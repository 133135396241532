<template>
 <div>
      <h1 class="display-4">HeatMapFX</h1>
        <hr class="my-4">
      <div class="border rounded">
          <div class="row align-items-center">
            <div class="col"></div>
            <div class="col">
              Debug Info
            </div>
             <div class="col"></div>
          </div>
          <div class="row align-items-center">
                <span class="col">{{ auth.authenticated ? 'Authenticated' : 'Not authenticated'}}</span>
                <!-- <span>{{ auth.loading ? 'Loading' : 'Not loading'}}</span> -->
                <span class="col">User: {{ auth.user?.sub.slice(15,33) || 'Not Logged In' }}</span>
              <div v-if="auth.authenticated" class="col">
              <img  class="img col" :src="auth.user?.picture" alt="Profile picture"/>
              </div>
          </div>
          <div class="row align-items-center">
                <div class="col"></div>
                <div class="col">
                  <a  v-if="!auth.authenticated" class="btn btn-light nav-link" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</a>
                  <a  v-if="auth.authenticated" class="btn btn-light nav-link" v-on:click="auth.logout({returnTo:this.$returnToUrl})" :disabled="!auth.authenticated">Logout</a>
                </div>
                <div class="col"></div>
              <!-- </div> -->
          </div>
      </div>
       <p class="lead">
            Disclaimer: I am not a licensed/certified financial planner/advisor. All content is for education purposes only. 
            HeatMapFX LLC and I will not be held liable for any actions taken by you as a result of what you learn or see. 
            Any trades taken, and any losses/profits made in the financial markets are under your discretion and your own responsibility. 
            Everything should be demo tested, and any decisions to do with investments of ANY SORT, are entirely YOUR choice. - HeatMapFX LLC
       </p>
       <p class="lead" id="disclaimer">

          <ul> Refund Policy: you are free to cancel your subscription at any time for future months (using the invoice in your email), 
                there are no refunds after payment is completed; once you have paid and been given access to the site, discord, etc, 
                  services are considered rendered and no refunds will be acceptable with exceptions only made by HeatMapFX LLC.
          </ul>
          <ul>
                RISK DISCLAIMER: There is risk associated with trading in a financial market. You are solely responsible for your own financial decisions. 
                HeatMapFX, its members and the third-party application providers that HeatMapFX may use for communication purposes will not be held 
                responsible for any losses you incur.
                HeatMapFX does not provide any trading advice and is not a professional investment service. 
          </ul>
          <ul>
            IMPORTANT NOTICE: No representation is being made that the use of this strategy, any strategy featured on this site,
             or any system or trading methodology will generate profits. Past performance is not necessarily indicative of future results. 
             There is a substantial risk of loss associated with trading currencies, securities, options on equities and options on futures.
              Only risk capital should be used to trade. Trading securities is not suitable for everyone.
          </ul>
          <ul>
           Disclaimer: Futures, Options, and Currency trading all have large potential rewards, but they also have large potential 
           risk and may result in monetary losses. You must be aware of the risks and be willing to accept them in order to invest or trade 
           in these markets. Don’t trade with money that you cannot afford to lose. The HeatMapFX site is neither a solicitation nor an offer to 
           Buy/Sell futures, options, or currencies. No representation is being made that any account will or is likely to achieve profits or 
           losses similar to those discussed on this web site. The past performance of any trading system or methodology is not necessarily
            indicative of future results.
          </ul>
          <ul>
            CFTC RULE 4.41 CFTC RULE 4.41 – HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE CERTAIN LIMITATIONS. UNLIKE AN ACTUAL PERFORMANCE RECORD,
             SIMULATED RESULTS DO NOT REPRESENT ACTUAL TRADING. ALSO, SINCE THE TRADES HAVE NOT BEEN EXECUTED, THE RESULTS MAY HAVE UNDER-OR-OVER 
             COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN MARKET FACTORS, SUCH AS LACK OF LIQUIDITY. SIMULATED TRADING PROGRAMS IN GENERAL ARE ALSO 
             SUBJECT TO THE FACT THAT THEY ARE DESIGNED
          </ul>
          <ul>
            Your past, present or future membership on HeatMapFX is an acknowledgement of this disclaimer and policy. You have read this and agree to it.
          </ul>

       </p>
 </div>
</template>
<script>
export default {
  name: 'Home',
  inject: ['auth'],
}
</script>
<style scoped>
.img{
  height: 128px;
  width: 128px;
}
</style>

  <!-- <div class="row">
              <button class="col" v-on:click="auth.loginWithPopup()" :disabled="auth.authenticated">Login with PopUp</button>
              <button class="col" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login with Redirect</button>
              <button class="col" v-on:click="auth.logout()" :disabled="!auth.authenticated">Logout</button>
              </div> -->